body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.layout {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
}

.star-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.send-button {
  background-color: #0366d6; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
}

.layout-header {
  display: flex;
  padding: 12px 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.layout-header-btn {
  margin: 0 12px;
  opacity: 0.3;
  cursor: pointer;
}
.layout-header-btn-current {
  opacity: 1;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.common-container {
  padding: 24px;
  line-height: 24px;
  color: #ccc;
}
.common-card {
  margin-bottom: 24px;
}
.common-title {
  font-size: 15px;
  font-weight: bold;
}
a {
  color: #fff;
}
.ant-pro-page-container-children-content {
  padding: 15px !important;
  margin: 0px !important;
  overflow: auto;
  width: 100%;
  height: calc(100vh - 50px - 48px);
}
